import React, { Component } from 'react'
import Img from 'gatsby-image'

class Services extends Component {
  render() {
    const { services } = this.props
    return (
      <>
        {services.map(({ node }) => (
          <div className="col s12 m6 l3">
            <div className="card center-align hoverable card-home">
              <div className="card-image card-service">
                {node.acf.icon ? (
                  <Img
                    fixed={node.acf.icon.localFile.childImageSharp.fixed}
                    alt={node.title}
                  />
                ) : null}
              </div>
              <div className="card-content">
                <span className="card-title">{node.title}</span>
                <div dangerouslySetInnerHTML={{ __html: node.content }} />
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default Services
