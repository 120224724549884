import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

class Portfolios extends Component {
  render() {
    const { portfolios, parallax } = this.props
    return (
      <>
        {portfolios.map(({ node }) => (
          <div className="col s12 m6 l3 spacing">
            <div className="card recent-posts">
              <div className="card-image">
                {node.featured_media ? (
                  <Img
                    fluid={node.featured_media.localFile.childImageSharp.fluid}
                    alt={node.title}
                  />
                ) : (
                    <Img
                      fluid={parallax.childImageSharp.fluid}
                      alt={node.title}
                    />
                  )}

                {/* {node.portfolio_category.map(({ name }) => (
                  <span className="card-title port-cat-home">{name} </span>
                ))} */}

                <Link
                  to={node.link}
                  className="btn-floating halfway-fab waves-effect waves-light purple"
                >
                  <i className="material-icons">add</i>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default Portfolios
