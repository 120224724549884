import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../utils/PageHeader'
import Services from '../utils/home/Services'
import Portfolios from '../utils/Portfolios'
import Calltoaction from '../components/calltoaction'

const PortfoliosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(wordpress_id: { eq: 3 }) {
        title
        content
        featured_media {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
      }
      allWordpressWpPortfolio(sort: { fields: date, order: ASC }) {
        edges {
          node {
            wordpress_id
            title
            link
            portfolio_category
            featured_media {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      allWordpressWpServices(sort: { fields: date, order: ASC }) {
        edges {
          node {
            title
            content
            acf {
              icon {
                localFile {
                  childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Portfolios"
        keywords={['portfolio', 'projects', 'headless wordpress', 'gatsby']}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      />
      <PageHeader
        img={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="container">
          <h1 className="portfolio-heading">Portfolios</h1>
        </div>
      </PageHeader>

      {/*Portfolio*/}
      <section className="blog container" style={{ marginTop: '50px' }}>
        <div className="row">
          <Portfolios portfolios={data.allWordpressWpPortfolio.edges} />
        </div>
      </section>
      {/*Services*/}
      <section className="section container services">
        <h2 className="center-align purple-text text-darken-3">Things I do</h2>
        <div className="row">
          <Services services={data.allWordpressWpServices.edges} />
        </div>
      </section>
      {/*Contact*/}
      <Calltoaction />
    </Layout>
  )
}

export default PortfoliosPage
